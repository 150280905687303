.Rectwith-box {
  /* background-color: #bacdce; */
  /* width: 100%; */
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  position: sticky;
}

.box-content {
  flex: 1;
}

.box-buttons {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 1.4rem;
  right: 1rem;
}

.inventory {
  font-size: 30px;
  font-weight: 350;
  margin: 0;
  padding: 0;
  /* font-size: 24px; */
  font-weight: bold;
  font-family: Arial, sans-serif;
 
  /* color: rgb(41, 37, 37); */
}

.farm-box {
  background-color:#ecf4f0;
  /* background-color:green; */
 
  box-sizing: border-box;
  height: 377px;
  margin-top: -1.7%;
  padding: 20px;
  /* position: relative; */
  width: 100%;
  max-width: 100%;
  /* min-width: 800px; */
  /* overflow-x: hidden; */
}
.farm-box-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.grid1-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 6px;
  /* height: calc(100% - 50px); subtract the height of the button container */
  overflow: hidden;
  height: 92%;
  width: 102%;
  margin-left: -1%;
}

/* .cropStageContainer {
      overflow-y: auto;
      white-space: nowrap;
      
    } */

.cropStage,
.harvestingGraph,
.dataDisplay,
.damageGraph {
  background-color: #ffffff;
  padding: 10px;
  position: relative;
  left: 2px;
  border-radius: 8px;

  text-align: center;
  box-shadow: 5px 2px #f1f4f1;
}
.stage-wrapper {
  position: relative;
  left: 0px;
}
.harvest-wrapper {
  position: relative;
  left: 0px;
}
.damage-wrapper {
  position: relative;
  left: -8px;
}
.cropStage > div {
  display: flex;
  justify-content: center;
}
.dataDisplay > div {
  display: flex;
  justify-content: center;
}
.harvestingGraph > div {
  display: flex;
  justify-content: center;
}
.damageGraph > div {
  display: flex;
  justify-content: center;
}

.farm-box-button-container {
  bottom: -30px;
  height: 45px;
  /* right: 0px; */
  position: absolute;
  left: 203px;
  right: 0;
  width: 60%;
}

.farm-box-button {
  /* background-color: #d7e5d8; */
  background-color: #2c7b55;

  border: none;
  border-radius: 30px;
  color:#ffffff;
  cursor: pointer;
  font-size: 18px;
  height: 70%;
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .grid1-container {
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    margin-bottom: 30px;
  }
  .farm-box-button-container {
    /* bottom: 10px; */
    height: 45px;
    left: 45%;
    transform: translateX(-50%);
    width: 90%;
    position: absolute;
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    .grid1-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .farm-box {
    height: auto;
  }
}
p {
  display: block;
  /* font-size: medium; */
  font-weight: 550;
  color: #010101;
}

/* .inventory-title {
  display: flex;
  align-items: center;
} */

.inventory {
  margin-right: 10px;
}
.icon-plant {
  margin-top: -1px;
  margin-left: 6px;
}
.icon-harvest {
  margin-left: 6px;
  margin-top: -4px;
}
.icon-damage {
  margin-left: 6px;
  margin-top: 0px;
}
.icon-area {
  margin-left: 6px;
  margin-top: -1px;
}
