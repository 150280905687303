.sidebarwms {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  /* width: 50px; */
  background-color: #34519fe6;
  /* #EA6A47 */
  /* background-color: #2c7b55; */
  z-index: 1000;
  transition: width 0.3s ease;
  overflow-x: hidden;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.expanded {
   
    /* background-color: #2c7b55; */
    background-color: #34519fe6;
  }
  border: 0.5px solid #f4f1f1;
}
/* .sidebarwms.expanded {
  width: 150px;
} */
.sidebarwms1 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  /* width: 50px; */
  background-color: #ffffff;
  /* #EA6A47 */
  /* background-color: #2c7b55; */
  z-index: 1000;
  transition: width 0.3s ease;
  overflow-x: hidden;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.expanded {
    /* background-color: #2c7b55; */
    background-color: #ffff;
  }
  border: 0.5px solid #f4f1f1;
}
.sidebarwms-logo {
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 8px;
}

.sidebarwms-icons {
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  text-align: center;
  z-index: 1000;
}

.menu-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-item-link {
  text-decoration: none;
}

.icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 1px;
}

.icon-name {
  margin-left: 8px;
  margin-right: 8px;
  font-size: 16px;
  font-weight: bold;
}
.sidebarwms-icon:not(:last-child) {
  margin-bottom: 50px;
}

.logo-image {
  &.logo-open {
    width: 120px;
    height: 65px;
    margin-left: -42px;
    margin-top: -10px;
  }

  &.logo-closed {
    width: 50px;
    height: 65px;
    margin-left: -10px;
    margin-top: -10px;
    text-align: center;
  }
}
a {
  color: white;
  text-decoration: none;
}

.sidebarwms-icon {
  color: white;
}
.sidebarwms-icon:hover {
 
  color: white;
}
.sidebarwms.expanded .sidebarwms-icon {
  color: white;
}
.recipeimg {
  filter: invert(0%);
}

.sidebarwms.expanded .recipeimg {
  filter: invert(0%);
}
.reportimg {
  filter: invert(100%);
}

.sidebarwms.expanded .reportimg {
  filter: invert(100%);
}

/* Add these styles to your existing stylesheet or create a new one */
.circle-container {
  position: fixed;
  
  left: 2%; /* Adjust as needed based on your layout */
 top: -78%;
  bottom: 0;
  overflow: visible;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1001; /* Make sure it's above the sidebar */
}
.circle-container.expanded{
  display: flex;
  justify-content: flex-end;
  /* margin-left: 9%; */
  /* position: fixed; */
  /* position: relative; */
  /* position: relative; */
   
  /* Adjust as needed */
  overflow: visible;
  z-index: 1001;
}
.circle-container.collapsed{
  display: flex;
  justify-content: flex-end;
  /* margin-left: 2%; */
  /* position: relative; */
  /* position: fixed; */
   
  overflow: visible;
  z-index: 1001;
}
.sidebarwms-circle-container {
  display: flex;
  justify-content: flex-end;
  margin-left: 60px;
  position: relative;
  
 
  overflow: visible;
  z-index: 999;
}

.sidebarwms-circle {
  background-color:  #34519fe6; /* Circle background color */
 border-radius: 78%;
  padding: 6px; /* Adjust as needed */
  margin-top: 1%; /* Adjust as needed */
  cursor: pointer;
  color:#ffff;
  /* margin-bottom: 28%; */
  transition: background-color 0.3s ease;
  position: absolute;
  z-index: 888; /* Place the circle icon above other elements */
}



.sidebarwms.expanded .sidebarwms-circle .circle-container.expanded{
  margin-left: 0px; /* Adjust as needed */
}

.sidebarwms.expanded .sidebarwms-circle:hover {
  
  color:#ffffff; 
}