/* TelemetryGraph.css */
.telemetry-container {
    display: flex;
    width: 100%;
    height: 100vh; /* You can adjust the height as needed */
    overflow: hidden; /* Prevents content from overflowing */
  
    /* Optional styling for the divider */
.MuiDivider-vertical {
      background-color: #020202; /* Change the background color as desired */
      width: 1px; /* Width of the vertical divider */
      margin-top: 2%;
      margin-bottom: 27%;
    }
  }
  
  .left-panel {
    flex: 1; /* Take up all available space in the flex container */
    overflow-y: auto; /* Add vertical scrolling if content overflows */
  }
  
  .right-panel {
    flex: 1; /* Take up all available space in the flex container */
    overflow-y: auto; /* Add vertical scrolling if content overflows */
  }
  