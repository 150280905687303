.app {
  display: grid;
  grid-template-columns: auto 20fr;
  grid-template-rows: 100vh;
  background-color: "#8acfe640";
}
 
.app3 { 
  display: grid;
  grid-template-columns: auto 20fr;
  grid-template-rows: 100vh;
  background-color: "#ffffff";
}
.sidebar-container {
  overflow: auto;
  display: grid;
  width: 61px;
  object-fit: cover;
}
.sidebar-container .sidebar {
  flex: 1;
}
.content-container {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 93vw;
}

.dashboard-container {
  margin-top: 0.9%;
}
.topbar-container {
  display: grid;

  height: "4px";
}

.route-wrapper {
  width: 100vw;
  height: 100%;
}

.login-container {
  width: 100vw;
}
.login-page .content-container {
  width: 100%;
}
