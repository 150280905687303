.sidebaratt {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 50px;
    background-color:#5e89c7;
    /* background-color: #2c7b55; */
    z-index: 1000;
    transition: width 0.3s ease;
    overflow-x: hidden;
  
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.expanded {
      /* background-color: #2c7b55; */
      background-color: #5e89c7;
    }
    border: 0.5px solid #f4f1f1;
  }
  .sidebaratt.expanded {
    width: 150px;
  }
  
  .sidebaratt-logo {
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 8px;
  }
  
  .sidebaratt-icons {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .menu-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .menu-item-link {
    text-decoration: none;
  }
  
  .icon-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 1px;
  }
  
  .icon-name {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 16px;
    font-weight: bold;
  }
  .sidebaratt-icon:not(:last-child) {
    margin-bottom: 50px;
  }
  
  .logo-image {
    &.logo-open {
      width: 120px;
      height: 65px;
      margin-left: -42px;
      margin-top: -10px;
    }
  
    &.logo-closed {
      width: 50px;
      height: 65px;
      margin-left: -10px;
      margin-top: -10px;
      text-align: center;
    }
  }
  a {
    color: white;
    text-decoration: none;
  }
  
  .sidebaratt-icon {
    color: white;
  }
  
  .sidebaratt.expanded .sidebaratt-icon {
    color: white;
  }
  .recipeimg {
    filter: invert(0%);
  }
  
  .sidebaratt.expanded .recipeimg {
    filter: invert(0%);
  }
  .reportimg {
    filter: invert(100%);
  }
  
  .sidebaratt.expanded .reportimg {
    filter: invert(100%);
  }
  