table.confirm-table{
    width:80%;
}
.confirm-table {
    border-collapse: collapse;
    font-family: Lexend Deca;
    font-size: 12px;
    width:'80%';
   
    
  }

.confirm-table thead{
    width:'80%';
}
.confirm-table th,
.confirm-table td {
    padding: 2px;
    border: 1px solid #ddd;
    
  }
  
.confirm-table th {
    text-align: left;
   
    
  }
  
.confirm-table td:first-child {
    font-weight: bold;
  }
  
.confirm-table tr:last-child td {
    border-bottom: 1px solid #ddd;
  }
  
  