table.confirm-table{
    width:130%;
  }
  .confirm-table {
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-size: 14px;
    width:'130%';
  }
  
  .confirm-table thead{
    width:'130%';
  }
  .confirm-table th,
  .confirm-table td {
    padding: 2px;
    border: 1px solid #ddd;
    
  }
  
  .confirm-table th {
    text-align: left;
    background-color: #f2f2f2;
    
  }
  
  .confirm-table td:first-child {
    font-weight: bold;
  }
  
  .confirm-table tr:last-child td {
    border-bottom: 1px solid #ddd;
  }
  
  .confirm-table tr:hover {
    background-color: #f5f5f5;
  }
  