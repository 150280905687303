.weekly-weather-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: transparent;

  width: 100%;
  height: 100%;
}

.weekly-weather-item {
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;

  padding: 5px;
  border-radius: 10px;
  background-color: hwb(173 93% 4%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 130px;
}

.weekly-weather-container > .weekly-weather-item {
  margin-right: 10px;
}

.weekly-weather-container > .weekly-weather-item:last-child {
  margin-right: 10px;
}

.weather-day {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 3px;
}

.weather-icon {
  font-size: 24px;
  margin-bottom: 3px;
}

.weather-condition {
  font-size: 12px;
  margin-bottom: 3px;
  text-align: center;
}

.weather-temperature {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.weather-info-item {
  font-size: 12px;
  margin-bottom: 1px;
}

.weekly-weather-title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.current-details {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  position: relative;
  left: -30px;
  top: -30px;
}

.current-details .current-humidity,
.current-details .current-speed,
.current-details .current-pressure {
  margin-left: 10px;
}
.current-detail-item {
  margin-bottom: 5px;
}

.location {
  font-weight: regular;
  font-size: 32px;
  text-align: left;
  color: #18447d;
  margin-right: 10px;
  position: relative;
  top: 30px;
  left: -20px;
}

.current-temperature {
  font-weight: bold;
  font-size: 42px;
  text-align: left;
  color: #396bae;
  margin-bottom: 10px;
  position: relative;
  top: -30px;
  left: 10px;
}

@media screen and (max-width: 1240px) {
  .current-temperature,
  .location,
  .current-details {
    font-size: 36px;
    margin-left: 25px;
  }
}

@media screen and (max-width: 1508px) {
  .weekly-weather-item {
    flex-basis: calc(1% - 1px);
    margin-right: 200px;
    width: 80px;
    height: 120px;
    margin: 2px;
    padding: 4px;
  }
}

@media screen and (max-width: 1443px) {
  .weekly-weather-item {
    margin-right: 200px;
    width: 60px;
    height: 120px;
    margin: 2px;
    padding: 4px;
  }
}
@media screen and (max-width: 1236px) {
  .weekly-weather-item {
    flex-basis: calc(1% - 1px);
    margin-right: 200px;
    width: 40px;
    height: 120px;
    margin: 2px;
    padding: 4px;
  }
  .weather-temperature {
    font-size: 10px;
    font-weight: bold;
  }
  .weather-day {
    font-size: 10px;
    font-weight: bold;
  }
}
@media screen and (max-width: 1044px) {
  .weekly-weather-item {
    flex-basis: calc(1% - 1px);
    margin-right: 200px;
    width: 40px;
    height: 120px;
    margin: 2px;
    padding: 4px;
  }
  .weather-temperature {
    font-size: 10px;
    font-weight: bold;
  }
  .weather-day {
    font-size: 10px;
    font-weight: bold;
  }
  .location {
    font-size: 24px;
  }
  .current-temperature {
    font-size: 32px;
  }

  .current-details > div {
    margin-top: 1px;
  }

  .current-details span {
    font-size: 12px;
  }

  .current-details svg {
    width: 14px;
    height: 14px;
    margin-left: 1px;
  }
}
@media (max-width: 940px) {
  .current-details {
    flex-direction: row;
    /* align-items: center; */
    margin-left: 20px;
  }

  .current-details > * {
   
  }

  .current-details span {
    font-size: 12px;
  }
}

@media (max-width: 928px) {
  .weekly-weather-item {
    width: 40px;
    height: 100px;
    margin: 2px;
    padding: 4px;
  }
  .location {
    font-size: 16px;
  }
}
@media screen and (max-width: 841px) {
  .weekly-weather-item {
    flex-basis: calc(1% - 1px);
    margin-right: 250px;
    width: 30px;
    height: 100px;
    margin: 2px;
    padding: 4px;
    margin-bottom: 6px;
    margin-top: -3px;
  }
  .left-item {
    margin-right: -30px;
  }
  .weather-temperature {
    font-size: 7px;
    font-weight: bold;
  }
  .weather-day {
    font-size: 6px;
    font-weight: bold;
  }
  .weather-icon {
    font-size: 2px;
  }
  .weather-condition {
    font-size: 8px;
  }
  .location {
    font-size: 22px;
    position: relative;
    top: 20px;
    margin-left: 5px;
  }
  .current-temperature {
    font-size: 22px;
    top: 0px;
    left: 10px;
  }
  .current-details {
    flex-direction: row;
    margin-left: -20px;
  }

  .current-details .current-humidity,
  .current-details .current-speed,
  .current-details .current-pressure {
    margin-right: 5px;
    position: relative;
    bottom: -10px;
    margin-left: 10px;
  }

  .current-details .current-humidity span,
  .current-details .current-speed span,
  .current-details .current-pressure span {
    font-size: 12px;
    margin-left: -5px;
  }

  .current-details .current-humidity svg,
  .current-details .current-speed svg,
  .current-details .current-pressure svg {
    font-size: 12px;
    margin-left: 1px;
  }
}
@media screen and (max-width: 703px) {
  .weekly-weather-item {
    flex-basis: calc(1% - 1px);
    margin-right: 250px;
    width: 30px;
    height: 100px;
    margin: 2px;
    padding: 4px;
    margin-bottom: 6px;
    margin-top: -3px;
    margin-left: 1px;
  }
  .left-item {
    margin-right: -10px;
  }
  .weather-temperature {
    font-size: 7px;
    font-weight: bold;
  }
  .weather-day {
    font-size: 6px;
    font-weight: bold;
  }
  .weather-icon {
    font-size: 2px;
  }
  .weather-condition {
    font-size: 8px;
  }
  .location {
    font-size: 18px;
    position: relative;
    top: -5px;
    margin-left: 25px;
  }
  .current-temperature {
    font-size: 18px;
    top: -30px;
    left: 30px;
  }
  .current-details {
    flex-direction: row;
    margin-left: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    position: relative;
    left: -20px;
    top: -40px;
  }
  .current-details .detail-text {
    font-size: 14px;
    font-family: "Lexend Deca";
    color: "#000000";
  }

  .current-details .current-humidity,
  .current-details .current-speed,
  .current-details .current-pressure {
    margin-right: 5px;
    position: relative;
    bottom: -30px;
    margin-left: 2px;
    display: flex;
  }

  .current-details .current-speed span,
  .current-details .current-pressure span {
    font-size: 10px;
    margin-left: -5px;
  }
  .current-details .current-humidity span {
    font-size: 10px;
    margin-left: 5px;
  }

  .current-details .current-humidity svg,
  .current-details .current-speed svg,
  .current-details .current-pressure svg {
    font-size: 10px;
    margin-left: 4px;
  }
}
@media screen and (max-width: 569px) {
  .weekly-weather-item {
    flex-basis: calc(1% - 1px);
    margin-right: 200px;
    width: 25px;
    height: 78px;
    margin: 2px;
    padding: 4px;
    margin-bottom: 6px;
    margin-top: -3px;
  }
  .weather-temperature {
    font-size: 7px;
    font-weight: bold;
  }
  .weather-day {
    font-size: 6px;
    font-weight: bold;
  }
  .weather-icon {
    font-size: 2px;
  }
  .weather-condition {
    font-size: 8px;
  }
  .location {
    font-size: 12px;
    position: relative;
    top: -10px;
    margin-left: 45px;
  }
  .current-temperature {
    font-size: 12px;
    top: -20px;
    left: 30px;
  }
}
@media screen and (max-width: 481px) {
  .weekly-weather-item {
    flex-basis: calc(1% - 1px);
    margin-right: 200px;
    width: 20px;
    height: 70px;
    margin: 2px;
    padding: 4px;
    margin-bottom: 6px;
    margin-top: -3px;
  }
  .weather-temperature {
    font-size: 4px;
    font-weight: bold;
  }
  .weather-day {
    font-size: 4px;
    font-weight: bold;
  }
  .weather-icon {
    font-size: 2px;
  }
  .weather-condition {
    font-size: 4px;
  }
  .location {
    font-size: 8px;
  }
  .current-temperature {
    font-size: 8px;
  }
}
@media screen and (max-width: 457px) {
  .weekly-weather-item {
    flex-basis: calc(1% - 1px);
    margin-right: 200px;
    width: 10px;
    height: 50px;
    margin: 2px;
    padding: 4px;
    margin-bottom: 6px;
    margin-top: -3px;
  }
  .weather-temperature {
    font-size: 4px;
    font-weight: bold;
  }
  .weather-day {
    font-size: 4px;
    font-weight: bold;
  }
  .weather-icon {
    font-size: 2px;
  }
  .weather-condition {
    font-size: 4px;
  }
  .location {
    font-size: 8px;
    position: relative;
    top: -10px;
    margin-left: 45px;
  }
  .current-temperature {
    font-size: 8px;
    top: -20px;
    left: 30px;
  }
}
