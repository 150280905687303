.varietyTable {
  border-collapse: collapse;
  width: 100%;
}

.varietyTable th,
.varietyTable td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #dbd9d9;
}

#crop-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

#crop-table td {
  border-bottom: 1px solid #dbd9d9;

  padding: 5px;
}
