.btn-wrapper{
    margin-top: 1.1rem;
    display: flex;
    justify-content: center;
   
    
  }
  
  
  @media (max-width: 600px) {
    .btn-wrapper{
      flex-direction: column;
      align-items: center;
    }
      .box-content {
       
        left: 0;
      }
    
      .box-content button {
        display: block;
        width: 35%;
        margin-bottom: 10px;
      }
    }
    .button-text {
      display: none;
      margin-left: 5px;
      font-size: 14px;
      font-weight: 500;
    }
    
   