
  .box-container {
    display: flex;
    justify-content: center;
  }
  
  .crop-box {
    margin: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    width: 50%;
  }
  
  .crop-name {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .stacked-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .bar-segment {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 90%; /* or 100% */
    padding: 0 5px; /* adjust as needed */
    margin: 0;
  }
  
  .bar-segment-text {
    color: #fff;
    padding: 0 ;
    margin: 0;
  }
  
  .apexcharts-tooltip {
    color: #000000 !important;
  }
  