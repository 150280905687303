.login-page4 {
     /* background-image: url("https://search.brave.com/images?q=fingerprint%20attendance%20image%20free");  */
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 96%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure this z-index is higher than other components */
  }
  
  .login-box {
    position: relative;
    z-index: 9999; /* Ensure this z-index is higher than other components */
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  
  .login-box h2 {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
  }
  
  .login-page form button[type="submit"] {
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    border: none;
    border-radius: 5px;
    background-color: #27810e;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .login-page form button[type="submit"]:hover {
    background-color: #27810e;
  }
  
  .login-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
  
  .left-section,
  .right-section {
    display: inline-block;
  }
  
  .right-section {
    text-align: right;
  }
  
  .left-section input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .error-message {
    color: red;
  }
  