.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Center vertically on the page */
    background-color: #f0f0f0; /* Background color */
  }
  
  .imageContainer {
    margin-bottom: 20px;
    width:'20%';
    height:'30%';
    background-color: aquamarine;
    marginTop:'4%';
  }
  
  .loginContainer {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loginButton {
    margin-top: 20px;
    background-color: green; /* Change button color to green */
    color: white;
  }
  
  .tooltipText {
    background-color: red;
    color: white;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
  }
  .login-page1{
    background-color: #d9e9d4;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .login-box {
   
     
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  
  .login-box h2 {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    
  }
  
  .login-page form button[type="submit"] {
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    border: none;
    border-radius: 5px;
    background-color: #27810e;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .login-page form button[type="submit"]:hover {
    background-color: #27810e;
  }
  
  
  .login-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
  
  .left-section,
  .right-section {
    display: inline-block;
  }
  
  .right-section {
    text-align: right;
  }
  
  .left-section input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .error-message {
    color: red;
  }
  /* Custom CSS class for sliding in from right to left */
.alert-slide-in {
  animation: slide-in 0.5s ease-in-out;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
