.appwms {
  display: grid;
  grid-template-columns: auto 20fr;
  grid-template-rows: 100vh;
  background-color: #ffffff;
}

.sidebar-container {
  overflow: auto;
  display: grid;
  width: 61px;
  object-fit: cover;
}
.sidebar-container .sidebar {
  flex: 1;
}

.content-container {
 
  transition: margin-left 0.3s ease;
}

.dashboard-container {
  margin-top: 0.9%;
}
.topbar-container1 {
  display: grid;
  margin-left: 5%;
  height: "4px";
}

.footer-container {
  display: grid;
  
  height: "10px";
  width: "100%";
}

.route-wrapper {
  width: 100vw;
  height: 100%;
}

.login-container {
  width: 100vw;
}
.login-page .content-container {
  width: 100%;
}
