

.control-box {
    width: 100%;
    height: 20%;
    padding: 8px;
    background-color: #ffffff;
    border-radius: 1px;
    border: 0.5px solid #ccc;
    
  }
  
  .control-box-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left {
    display: flex;
    align-items: center;
    text-align: left;
  
    width:"50%";
    
  }
  
  .right {
    display: flex;
    align-items: center;
    text-align: right;
    margin-right:20px;
  }
  
  .control-box-button {
    background-color: #d8e7de;
    color: #e13333;
    border: none;
    padding: 7px;
    border-radius: 2px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.2s ease;
    position:relative;
    margin-top: auto;
  }
  
  .control-box-button:hover {
    background-color: #555;
  }
  
  .control-box-button.active {
    background-color: #29CC97;
  }
  .textstyle{
    font-size: Medium;
    margin-left: 30px;
  

  }
  .device-name{
    font-size: 14px;
    margin-left: 20px;
  }

  @media only screen and (max-width: 768px) {
    .control-box {
      padding: 8px;
    }
    
    .control-box-button {
      padding: 5px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .control-box {
      padding: 6px;
    }
    
    .control-box-item {
      flex-direction: column;
    }
    
    .left {
      margin-bottom: 5px;
    }
    
    .right {
      justify-content: flex-end;
    }
    
    .control-box-button {
      padding: 4px;
      font-size: 0.8rem;
    }
  }
  