.grid-box {
  display: flex;
  flex-direction: column;
  overflow: 'scroll';
  background-color: '';
  align-items: center;
  padding: 10px;
}

.button-container {
  align-items: center;
   overflow: 'scroll';
  margin-bottom: 105px;
}

.button-container button {
  align-items:  'center';
  margin-right: 5px;
  margin-left: 500px;
}

.grid {
  border-radius: 10px;
  overflow: hidden;
  background-color: lightgrey;
  margin-left:1100px;
}

.fixed-size-grid {
  width: 100%;
  height: 100%;
  direction: ltr;
}

.cell {
  position: relative;
  border: 0.1px solid black;
  overflow: hidden;
}
.cell-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: 'green';
  /* background-color: rgb(15, 99, 173); */
}

.emoticon {
  font-size: 36px;
  margin-right: 5px;
}

.index-number-display{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  overflow: auto;
  border: '5px';
}
.zoom-button{
  /* border-radius: 1px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.index-storing-grid{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  overflow: auto;

  border: 3px  black;
   height: 100% 
}
.grid-map-dt {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(193, 11, 11, 0.1);
  height: 100%; 
  overflow: auto;
  border: 3px  black;
   height: 100% 
}