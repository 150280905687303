/* Add these styles to your CSS file */


  
  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .date-fields {
    display: flex;
    gap: 10px; /* Adjust the gap as needed */
    margin-right: 7%;
    margin-top: 1%;
  }
  .chart-containerEC{
    height:75%;
    width:90%;
    margin-right: 6%;
    margin-left: 4%;

  }
  /* Additional styling for other elements as needed */
  