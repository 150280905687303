.device-card {
  flex: 0 0 auto;
  margin-right: 10px;
  height: 150px;
  width: 150px;
}

.humidity-wrapper {
  margin-right: -10px;
  margin-left: -5px;
}

@media screen and (max-width: 1079px) {
  .ph-wrapper,
  .ftemp-wrapper,
  .humidity-wrapper,
  .wtemp-wrapper,
  .ec-wrapper,
  .name-wrapper {
    margin-right: 80px;
    font-size: 14px;
  }
  .humidity-wrapper {
    margin-left: 20px;
    width: 80%;
  }
  .ph-wrapper {
    margin-left: 20px;
  }
  .ftemp-wrapper,
  .wtemp-wrapper,
  .ec-wrapper {
    margin-left: 20px;
  }
  .device-card {
    width: 200px;
  }
}
@media screen and (max-width: 768px) {
  .ph-wrapper,
  .ftemp-wrapper,
  .wtemp-wrapper,
  .ec-wrapper,
  .name-wrapper {
    margin-right: 120px;
    font-size: 12px;
    width: "80";
  }
  .humidity-wrapper {
    margin-right: 140px;
  }
  .ftemp-wrapper,
  .wtemp-wrapper {
    margin-top: -20px;
  }
  .device-card {
    width: 150px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 480px) {
  .ph-wrapper,
  .ftemp-wrapper,
  .wtemp-wrapper,
  .ec-wrapper,
  .name-wrapper {
    margin-right: 120px;
    font-size: 12px;
  }
  .humidity-wrapper {
    margin-right: 140px;
  }
  .device-card {
    width: 100%;
  }
}
